import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { countVisitesByDate, deleteVisitesByDate } from "../../../../services/visitesServices"; // Services pour récupérer et supprimer les données des visites
import { toast } from "react-toastify";

const VisitsChart = () => {
  const [data, setData] = useState([]);
  const [dateDebut, setDateDebut] = useState(""); // État pour la date de début
  const [dateFin, setDateFin] = useState(""); // État pour la date de fin
  const [loading, setLoading] = useState(false); // État pour gérer le chargement
  const [isDemoMode, setIsDemoMode] = useState(false);

  // Fonction pour vérifier le role_id dans localStorage
  const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem('user')); // Récupérer l'utilisateur depuis localStorage
    if (user && user.role_id === 4) {
      setIsDemoMode(true); // Activer le mode démo si role_id est 4
    }
  };

  // Récupérer les données des visites depuis l'API
  useEffect(() => {
    const fetchVisites = async () => {
      try {
        const response = await countVisitesByDate();
        setData(response);
      } catch (error) {
        console.error("Erreur lors de la récupération des données de visites", error);
      }
    };

    fetchVisites();
    checkUserRole(); // Vérifier le rôle de l'utilisateur au chargement du composant
  }, []);

  // Afficher un message si en mode démo
  const handleDemoClick = () => {
    if (isDemoMode) {
      toast.info('Fonction désactivée en mode Démonstration');
      return true; // Indique que l'action est désactivée en mode démo
    }
    return false; // Permet l'action si pas en mode démo
  };

  // Fonction pour gérer la suppression des visites
  const handleDelete = async () => {
    if (!dateDebut || !dateFin) {
      alert("Veuillez sélectionner les dates de début et de fin.");
      return;
    }

    setLoading(true);
    try {
      const response = await deleteVisitesByDate(dateDebut, dateFin);
      alert(response.message || "Visites supprimées avec succès");
      
      // Après la suppression, recharger les données mises à jour
      const updatedData = await countVisitesByDate();
      setData(updatedData);
    } catch (error) {
      console.error("Erreur lors de la suppression des visites :", error);
      alert("Une erreur est survenue lors de la suppression des visites.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-200 rounded-lg shadow-lg p-4 dark:bg-gray-800 dark:text-gray-200">
      <h3 className="text-lg font-bold text-gray-700 dark:text-gray-100 mb-4">
        Évolution des Visites
      </h3>
      <div style={{ position: "relative", zIndex: 0 }}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid
              strokeDasharray="3 3"
              stroke="gray"
              className="dark:stroke-gray-300"
            />
            <XAxis
              dataKey="date_visite"
              stroke="#666"
              className="dark:stroke-gray-300"
            />
            <YAxis stroke="#666" className="dark:stroke-gray-200" />
            <Tooltip
              contentStyle={{
                backgroundColor: "#333",
                border: "none",
                color: "#fff",
              }}
              cursor={{
                stroke: "gray",
                strokeDasharray: "3 3",
              }}
            />
            <Legend wrapperStyle={{ color: "#333", fill: "#333" }} />
            <Line
              type="monotone"
              dataKey="compteur"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Zone pour la suppression des visites */}
      <div className="mt-8">
        <h4 className="text-lg font-bold text-gray-700 dark:text-gray-100">
          Supprimer des visites entre deux dates
        </h4>
        <p className="mb-4">Afin de ne pas alourdir la base de donnée, merci de supprimer les visites des saisons passées.</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Input pour la date de début */}
          <input
            type="date"
            className="p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            value={dateDebut}
            onChange={(e) => setDateDebut(e.target.value)}
          />

          {/* Input pour la date de fin */}
          <input
            type="date"
            className="p-2 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            value={dateFin}
            onChange={(e) => setDateFin(e.target.value)}
          />

          {/* Bouton pour supprimer */}
          <button
            className={`p-2 rounded text-white ${loading ? "bg-gray-500" : "bg-red-600"} hover:bg-red-700`}
            onClick={() => {
              if (!handleDemoClick()) handleDelete();
            }
            }
            disabled={loading}
          >
            {loading ? "Suppression en cours..." : "Supprimer les visites"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VisitsChart;
